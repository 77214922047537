* {
  color: #333;
  box-sizing: border-box;
  font-size: 0.32rem;
}
*:before,
*:after {
  box-sizing: border-box;
}
* {
  -webkit-tap-highlight-color: #333;
  color: #333;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0 none;
  color: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* 在X5新内核Blink中，在排版页面的时候，会主动对字体进行放大，会检测页面中的主字体，当某一块字体在我们的判定规则中，认为字号较小，并且是页面中的主要字体，就会采取主动放大的操作。然而这不是我们想要的，可以采取给最大高度解决 */
  max-height: 100000px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
em,
strong {
  font-style: normal;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
body {
  font-size: inherit;
}
.text-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
::selection {
  background: #FFF;
  color: #333;
}
::-moz-selection {
  background: #FFF;
  color: #333;
}
::-webkit-selection {
  background: #FFF;
  color: #333;
}

.cm-part-title {
  line-height: 0.8rem;
  border-bottom: 1px solid #eeeeee;
  font-size: 0.3rem;
  padding: 0 0.32rem;
  background: #fff;
}
.cm-part-title img {
  height: 30px;
  vertical-align: sub;
  margin-right: 10px;
}
.cm-blue {
  color: #4E8CEE;
}
.cm-dspt {
  font-size: 0.21rem;
  color: #999;
}
.primaryColor {
  color: #4E8CEE;
}
.user-share {
  margin-right: 0.3rem;
  height: 0.35rem;
  width: 0.35rem;
  vertical-align: middle;
}
.user-center {
  height: 0.35rem;
  width: 0.35rem;
  vertical-align: middle;
}
.course-number {
  font-size: 0.22rem;
  color: #666;
}
.course-price {
  font-size: 0.22rem;
  color: red;
}
.cm-people-icon {
  width: 25px;
  margin-right: 10px;
}
.van-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.title-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cm-part-title {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.am-carousel-wrap-dot.am-carousel-wrap-dot-active > span {
  background: #fff !important;
}
.am-carousel-wrap-dot > span {
  background: transparent!important;
  border: 1px solid #fff;
}
.home-index {
  background: red;
  font-size: 0.55rem;
}
.test {
  background: pink;
  width: 3.75rem;
}
.link-array {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home {
  background: #F5F5F5;
  /* 其他页面 */
  /* 基地信息 */
  /* 线下活动css */
  /* 最近资讯css */
}
.home .znc-logo-r {
  height: 0.34rem;
  margin-left: 0.3rem;
  vertical-align: middle;
}
.home .znc-logo {
  height: 0.34rem;
}
.home .share-icon {
  margin-right: 0.2rem;
  height: 0.34rem;
}
.home .header-icon {
  height: 0.34rem;
}
.home .home-header {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .home-header .znc-logo {
  vertical-align: middle;
  width: 1.87rem;
}
.home .home-other {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .home-other li {
  height: 2.08rem;
  background: #fff;
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 0.3rem 0 0 0;
}
.home .home-other li p {
  text-align: center;
  line-height: 0.48rem;
  font-size: 0.28rem;
}
.home .home-other img {
  width: 1rem;
  height: 1rem;
}
.home .base-information {
  margin-top: 0.2rem;
}
.home .base-information > h4 {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .offline-activity {
  background: #fff;
  margin: 0.2rem 0;
}
.home .offline-activity > h4 {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .offline-activity ul.ttt {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.15rem 0.32rem 0.32rem 0.32rem;
}
.home .offline-activity ul.ttt .active-content {
  width: 3.26rem;
  margin-top: 0.15rem;
  position: relative;
}
.home .offline-activity ul.ttt .active-content > img {
  position: absolute;
  width: 0.55rem;
  top: -0.05rem;
  left: 0.16rem;
}
.home .offline-activity ul.ttt .activity-img-cont {
  border-radius: 4px;
  overflow: hidden;
}
.home .offline-activity ul.ttt .activity-img-cont img {
  border-radius: 4px;
  width: 3.26rem;
  height: 2rem;
}
.home .offline-activity ul.ttt p {
  line-height: 0.58rem;
  font-size: 0.26rem;
  text-align: left;
}
.home .latest-news {
  background: #fff;
  margin: 0.2rem 0 0 0;
  overflow: hidden;
}
.home .latest-news-title {
  font-size: 0.26rem;
}
.home .latest-news-list {
  padding: 0 0.32rem;
}
.home .latest-news-list li {
  padding: 0.32rem 0;
  line-height: 0.42rem;
  text-align: left;
  border-bottom: 1px solid #eeeeee;
}
.home .latest-news-list .latest-news-bottom {
  margin-top: 0.2rem;
  color: #666;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .latest-news-list .latest-news-bottom span {
  font-size: 0.22rem;
}
.home .float_modeule {
  position: fixed;
  top: 0;
  right: 0rem;
}
.home .float_modeule img {
  width: 2rem;
}

.acitivity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.acitivity .acitivity-info {
  text-align: left;
  padding: 0.3rem 0.25rem 0;
  background: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
}
.acitivity .acitivity-info-top {
  font-size: 0.3rem;
  line-height: 0.45rem;
  padding-bottom: 0.2rem;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
}
.acitivity .acitivity-info-bottom {
  line-height: 0.65rem;
}
.acitivity .acitivity-info-bottom p {
  font-size: 0.26rem;
  color: #666666;
}
.acitivity .news-content {
  background: #fff;
  text-align: left;
  margin-top: 0.2rem;
}
.acitivity .news-content div {
  padding: 0.3rem 0.25rem;
}
.acitivity .news-content p {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.acitivity .MsoNormal {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.acitivity .MsoNormal span {
  font-size: 0.28rem;
  line-height: 0.5rem;
}
.acitivity img {
  margin-top: 0.2rem;
  width: 100%;
}

.zhinanche {
  text-align: left;
}
.zhinanche .m-base-line {
  border-top: 1px dashed #E5E5E5;
  border-bottom: none!important;
  margin: 0 0.2rem;
}
.zhinanche aside {
  padding: 0.4rem 0.5rem 0.5rem;
  line-height: 0.5rem;
  font-size: 0.28rem;
  color: #666;
}
.zhinanche .m-result-list li {
  padding: 0.4rem 0.5rem;
  color: #666;
  line-height: 0.55rem;
  border-bottom: 1px dashed #E5E5E5;
}
.zhinanche .m-result-list li h4 {
  color: #333;
  font-size: 0.32rem;
  margin-bottom: 0.2rem;
}
.zhinanche .m-result-list li p {
  font-size: 0.28rem;
}
.zhinanche .m-result-list li:last-child {
  border: none;
}
.zhinanche .model-warp {
  background: #fff;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.zhinanche-info {
  background: url(../../static/media/znc-in-bg.7c66a491.png) no-repeat !important;
  height: 3rem;
  background-size: 100% 100%;
}
.zhinanche-info img {
  height: 0.46rem;
  margin: 0.87rem auto 0.3rem;
  display: block;
}
.zhinanche-info p {
  color: #3f507e;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 0.34rem;
}

.news {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  background: #fff;
}
.news .news-info {
  text-align: left;
  margin: 0 0.25rem;
  padding-top: 0.3rem;
  border-bottom: 1px solid #eeeeee;
}
.news .news-info-top {
  font-size: 0.3rem;
  color: #333333;
}
.news .news-info-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 0.8rem;
}
.news .news-info-bottom span {
  font-size: 0.22rem;
  color: #666666;
}
.news .news-content {
  text-align: left;
  margin: 0.3rem 0.25rem;
}
.news .MsoNormal {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.news .MsoNormal span {
  font-size: 0.28rem;
  line-height: 0.5rem;
}
.news img {
  margin-top: 0.2rem;
  width: 100%;
}

.cm-form {
  margin: 0 1rem;
}
.cm-form .am-list-header {
  font-size: 0.38rem;
  color: #4e8cee;
}
.cm-form .am-list-item.am-input-item {
  margin-top: 0.4rem !important;
}
.cm-form .am-button {
  height: 0.7rem !important;
  line-height: 0.6rem !important;
}
.cm-form .am-list-item {
  padding-left: 0 !important;
}
.cm-form .am-list-item .am-input-label.am-input-label-5 {
  width: 0.7rem !important;
}
.cm-form .am-list-item .am-input-control input {
  font-size: 0.28rem !important;
}
.cm-form .am-list-item .am-input-extra {
  width: 1.57rem;
  font-size: 0.24rem;
  border-left: 1px dashed #19316b;
}
.form-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
}
.form-content .login-img {
  width: 100%;
  margin-bottom: 0.8rem;
}
.form-content .link-array {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 0.8rem;
  color: #333333;
  margin: 0 1rem;
}
.form-content .link-array li {
  font-size: 0.26rem;
}
html .am-list-body::before {
  background-color: transparent!important;
}

.server-znc {
  text-align: left;
}
.server-znc .zhinanche-info {
  background: url(../../static/media/znc-in-bg.7c66a491.png) no-repeat !important;
  height: 3rem;
  background-size: 100% 100%;
  overflow: hidden;
}
.server-znc .zhinanche-info img {
  height: 0.46rem !important;
  width: auto;
  margin: 0.87rem auto 0.3rem;
  display: block;
}
.server-znc .zhinanche-info p {
  color: #3f507e;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 0.34rem;
}
.server-znc .cm-wirte {
  background: #fff;
  padding-bottom: 0.4rem;
}
.server-znc .cm-wirte img {
  width: 100%;
}
.server-znc .cm-wirte p:not(.cm-part-title) {
  margin-top: 0.3rem;
  padding: 0 0.4rem;
  line-height: 0.5rem;
  font-size: 0.28rem;
  color: #333333;
}
.server-znc .cm-wirte p:not(.cm-part-title) span {
  font-size: 0.28rem;
  font-weight: 700;
}
.server-znc .cm-wirte .cm-part-title img {
  width: auto;
  height: 0.42rem;
}
.server-znc .cm-wirte .img-warp {
  margin: 0.3rem 0 0;
}
.server-znc .cm-wirte .img-warp ul {
  text-align: center;
  display: -webkit-flex;
  display: flex;
}
.server-znc .server-connect {
  margin-top: 0.4rem;
}

