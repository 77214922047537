.zhinanche {
  text-align: left;
}
.zhinanche .m-base-line {
  border-top: 1px dashed #E5E5E5;
  border-bottom: none!important;
  margin: 0 0.2rem;
}
.zhinanche aside {
  padding: 0.4rem 0.5rem 0.5rem;
  line-height: 0.5rem;
  font-size: 0.28rem;
  color: #666;
}
.zhinanche .m-result-list li {
  padding: 0.4rem 0.5rem;
  color: #666;
  line-height: 0.55rem;
  border-bottom: 1px dashed #E5E5E5;
}
.zhinanche .m-result-list li h4 {
  color: #333;
  font-size: 0.32rem;
  margin-bottom: 0.2rem;
}
.zhinanche .m-result-list li p {
  font-size: 0.28rem;
}
.zhinanche .m-result-list li:last-child {
  border: none;
}
.zhinanche .model-warp {
  background: #fff;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.zhinanche-info {
  background: url(../assets/img/znc-in-bg.png) no-repeat !important;
  height: 3rem;
  background-size: 100% 100%;
}
.zhinanche-info img {
  height: 0.46rem;
  margin: 0.87rem auto 0.3rem;
  display: block;
}
.zhinanche-info p {
  color: #3f507e;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 0.34rem;
}
