* {
  color: #333;
  box-sizing: border-box;
  font-size: 0.32rem;
}
*:before,
*:after {
  box-sizing: border-box;
}
* {
  -webkit-tap-highlight-color: #333;
  color: #333;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0 none;
  color: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* 在X5新内核Blink中，在排版页面的时候，会主动对字体进行放大，会检测页面中的主字体，当某一块字体在我们的判定规则中，认为字号较小，并且是页面中的主要字体，就会采取主动放大的操作。然而这不是我们想要的，可以采取给最大高度解决 */
  max-height: 100000px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
em,
strong {
  font-style: normal;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
body {
  font-size: inherit;
}
.text-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
::selection {
  background: #FFF;
  color: #333;
}
::-moz-selection {
  background: #FFF;
  color: #333;
}
::-webkit-selection {
  background: #FFF;
  color: #333;
}
