.title-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cm-part-title {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.am-carousel-wrap-dot.am-carousel-wrap-dot-active > span {
  background: #fff !important;
}
.am-carousel-wrap-dot > span {
  background: transparent!important;
  border: 1px solid #fff;
}
.home-index {
  background: red;
  font-size: 0.55rem;
}
.test {
  background: pink;
  width: 3.75rem;
}
.link-array {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home {
  background: #F5F5F5;
  /* 其他页面 */
  /* 基地信息 */
  /* 线下活动css */
  /* 最近资讯css */
}
.home .znc-logo-r {
  height: 0.34rem;
  margin-left: 0.3rem;
  vertical-align: middle;
}
.home .znc-logo {
  height: 0.34rem;
}
.home .share-icon {
  margin-right: 0.2rem;
  height: 0.34rem;
}
.home .header-icon {
  height: 0.34rem;
}
.home .home-header {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .home-header .znc-logo {
  vertical-align: middle;
  width: 1.87rem;
}
.home .home-other {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .home-other li {
  height: 2.08rem;
  background: #fff;
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 0.3rem 0 0 0;
}
.home .home-other li p {
  text-align: center;
  line-height: 0.48rem;
  font-size: 0.28rem;
}
.home .home-other img {
  width: 1rem;
  height: 1rem;
}
.home .base-information {
  margin-top: 0.2rem;
}
.home .base-information > h4 {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .offline-activity {
  background: #fff;
  margin: 0.2rem 0;
}
.home .offline-activity > h4 {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .offline-activity ul.ttt {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.15rem 0.32rem 0.32rem 0.32rem;
}
.home .offline-activity ul.ttt .active-content {
  width: 3.26rem;
  margin-top: 0.15rem;
  position: relative;
}
.home .offline-activity ul.ttt .active-content > img {
  position: absolute;
  width: 0.55rem;
  top: -0.05rem;
  left: 0.16rem;
}
.home .offline-activity ul.ttt .activity-img-cont {
  border-radius: 4px;
  overflow: hidden;
}
.home .offline-activity ul.ttt .activity-img-cont img {
  border-radius: 4px;
  width: 3.26rem;
  height: 2rem;
}
.home .offline-activity ul.ttt p {
  line-height: 0.58rem;
  font-size: 0.26rem;
  text-align: left;
}
.home .latest-news {
  background: #fff;
  margin: 0.2rem 0 0 0;
  overflow: hidden;
}
.home .latest-news-title {
  font-size: 0.26rem;
}
.home .latest-news-list {
  padding: 0 0.32rem;
}
.home .latest-news-list li {
  padding: 0.32rem 0;
  line-height: 0.42rem;
  text-align: left;
  border-bottom: 1px solid #eeeeee;
}
.home .latest-news-list .latest-news-bottom {
  margin-top: 0.2rem;
  color: #666;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.home .latest-news-list .latest-news-bottom span {
  font-size: 0.22rem;
}
.home .float_modeule {
  position: fixed;
  top: 0;
  right: 0rem;
}
.home .float_modeule img {
  width: 2rem;
}
