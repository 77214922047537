.news {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  background: #fff;
}
.news .news-info {
  text-align: left;
  margin: 0 0.25rem;
  padding-top: 0.3rem;
  border-bottom: 1px solid #eeeeee;
}
.news .news-info-top {
  font-size: 0.3rem;
  color: #333333;
}
.news .news-info-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 0.8rem;
}
.news .news-info-bottom span {
  font-size: 0.22rem;
  color: #666666;
}
.news .news-content {
  text-align: left;
  margin: 0.3rem 0.25rem;
}
.news .MsoNormal {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.news .MsoNormal span {
  font-size: 0.28rem;
  line-height: 0.5rem;
}
.news img {
  margin-top: 0.2rem;
  width: 100%;
}
