.acitivity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.acitivity .acitivity-info {
  text-align: left;
  padding: 0.3rem 0.25rem 0;
  background: #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
}
.acitivity .acitivity-info-top {
  font-size: 0.3rem;
  line-height: 0.45rem;
  padding-bottom: 0.2rem;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
}
.acitivity .acitivity-info-bottom {
  line-height: 0.65rem;
}
.acitivity .acitivity-info-bottom p {
  font-size: 0.26rem;
  color: #666666;
}
.acitivity .news-content {
  background: #fff;
  text-align: left;
  margin-top: 0.2rem;
}
.acitivity .news-content div {
  padding: 0.3rem 0.25rem;
}
.acitivity .news-content p {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.acitivity .MsoNormal {
  font-size: 0.28rem;
  line-height: 0.5rem;
  margin: 0 0.25rem;
  text-align: left;
  text-indent: 0.6rem;
}
.acitivity .MsoNormal span {
  font-size: 0.28rem;
  line-height: 0.5rem;
}
.acitivity img {
  margin-top: 0.2rem;
  width: 100%;
}
