.cm-form {
  margin: 0 1rem;
}
.cm-form .am-list-header {
  font-size: 0.38rem;
  color: #4e8cee;
}
.cm-form .am-list-item.am-input-item {
  margin-top: 0.4rem !important;
}
.cm-form .am-button {
  height: 0.7rem !important;
  line-height: 0.6rem !important;
}
.cm-form .am-list-item {
  padding-left: 0 !important;
}
.cm-form .am-list-item .am-input-label.am-input-label-5 {
  width: 0.7rem !important;
}
.cm-form .am-list-item .am-input-control input {
  font-size: 0.28rem !important;
}
.cm-form .am-list-item .am-input-extra {
  width: 1.57rem;
  font-size: 0.24rem;
  border-left: 1px dashed #19316b;
}
.form-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
}
.form-content .login-img {
  width: 100%;
  margin-bottom: 0.8rem;
}
.form-content .link-array {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 0.8rem;
  color: #333333;
  margin: 0 1rem;
}
.form-content .link-array li {
  font-size: 0.26rem;
}
html .am-list-body::before {
  background-color: transparent!important;
}
