.server-znc {
  text-align: left;
}
.server-znc .zhinanche-info {
  background: url(../assets/img/znc-in-bg.png) no-repeat !important;
  height: 3rem;
  background-size: 100% 100%;
  overflow: hidden;
}
.server-znc .zhinanche-info img {
  height: 0.46rem !important;
  width: auto;
  margin: 0.87rem auto 0.3rem;
  display: block;
}
.server-znc .zhinanche-info p {
  color: #3f507e;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 0.34rem;
}
.server-znc .cm-wirte {
  background: #fff;
  padding-bottom: 0.4rem;
}
.server-znc .cm-wirte img {
  width: 100%;
}
.server-znc .cm-wirte p:not(.cm-part-title) {
  margin-top: 0.3rem;
  padding: 0 0.4rem;
  line-height: 0.5rem;
  font-size: 0.28rem;
  color: #333333;
}
.server-znc .cm-wirte p:not(.cm-part-title) span {
  font-size: 0.28rem;
  font-weight: 700;
}
.server-znc .cm-wirte .cm-part-title img {
  width: auto;
  height: 0.42rem;
}
.server-znc .cm-wirte .img-warp {
  margin: 0.3rem 0 0;
}
.server-znc .cm-wirte .img-warp ul {
  text-align: center;
  display: -webkit-flex;
  display: flex;
}
.server-znc .server-connect {
  margin-top: 0.4rem;
}
