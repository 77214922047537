.cm-part-title {
  line-height: 0.8rem;
  border-bottom: 1px solid #eeeeee;
  font-size: 0.3rem;
  padding: 0 0.32rem;
  background: #fff;
}
.cm-part-title img {
  height: 30px;
  vertical-align: sub;
  margin-right: 10px;
}
.cm-blue {
  color: #4E8CEE;
}
.cm-dspt {
  font-size: 0.21rem;
  color: #999;
}
.primaryColor {
  color: #4E8CEE;
}
.user-share {
  margin-right: 0.3rem;
  height: 0.35rem;
  width: 0.35rem;
  vertical-align: middle;
}
.user-center {
  height: 0.35rem;
  width: 0.35rem;
  vertical-align: middle;
}
.course-number {
  font-size: 0.22rem;
  color: #666;
}
.course-price {
  font-size: 0.22rem;
  color: red;
}
.cm-people-icon {
  width: 25px;
  margin-right: 10px;
}
.van-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
